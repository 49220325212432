@tailwind base;
@tailwind components;
@tailwind utilities;

.mob-width {
  @apply max-w-[550px] m-auto w-full;
}

.kbd-row {
  @apply flex justify-center pb-1;
}

.kbd-key {
  @apply flex border-0 border-black rounded mr-1 shadow shadow-slate-500 cursor-pointer transition-transform hover:scale-110;
}

.info-box {
  @apply rounded bg-slate-100 font-bold p-1 dark:bg-[#afd5c2];
}

.bg-poker {
  background-color: #35664b;
}

.card-anim {
  transition: transform 0.3s;
}

.card-flipped {
  transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-faced {
  transform: rotateY(0deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.delay-100 {
  transition-delay: 200ms;
}

.delay-200 {
  transition-delay: 400ms;
}

.delay-300 {
  transition-delay: 600ms;
}

.delay-400 {
  transition-delay: 800ms;
}

.delay-500 {
  transition-delay: 1000ms;
}
